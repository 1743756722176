<form on:submit="onsubmit(event)">
	<input
		type="text"
		ref:input
		bind:value
		on:keydown="onkeydown(event)"
		placeholder="prov 30:2"
	>
</form>

<style>
input {
	font-size: 32px;
	border-radius: 4px;
	padding: 4px 8px;
	max-width: calc(100% - 24px);
}
</style>

<!--

- color the border green if the input can go somewhere valid
- expect:
	- current book, if any
- display:
	- currently inferred book name
	- currently understood chapter number
	- currently understood verse number

If it's feasible to validate chapter/verse numbers, then that validation
needs to be moved to a function in lib

Don't fire "submit" if the input isn't properly understood/green

-->

<script>
const code_escape = 27
const isEscape = event => event.key === 'Escape' || event.keyCode === code_escape

export default {
	data() {
		return {
			value: ''
		}
	},
	oncreate() {
		if (this.get().autofocus) {
			this.refs.input.focus()
		}
	},
	onstate({ changed, current }) {
		if (changed.value) {
			console.log('Inside the input, the value is', current.value)
		}
	},
	methods: {
		onkeydown(event) {
			if (isEscape(event)) {
				this.fire('escape')
			}
		},
		onsubmit(event) {
			this.fire('submit')

			event.preventDefault()
		},
	}
}
</script>
