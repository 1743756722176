<div class="container">
	<h1>
		Not Found
	</h1>
	{#if route}
		<p>No route for {route}</p>
	{/if}
	<p>
		<StateLink state="main.home">Home</StateLink>
	</p>
</div>

<style>
@import 'mixins';

.container {
	@include container-side-padding;
	@include container-vertical-padding;
}
</style>

<script>
import StateLink from 'component/StateLink.html'
export default {
	components: {
		StateLink
	}
}
</script>
