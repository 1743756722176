<a
	href="{href}"
	data-active="{stateIsActive(state, params)}"
	on:click="navigate(event)"
	class="{className}"
>
	<slot></slot>
</a>

<script>
import shouldInterceptClick from 'click-should-be-intercepted-for-navigation'

import mediator from 'lib/mediator-instance.js'

const stateIsActive = (...args) => mediator.callSync('stateIsActive', ...args)
const makePath = (...args) => mediator.callSync('makePath', ...args)



function currentPath() {
	return window.location.pathname + window.location.search
}

export default {
	oncreate() {
		this.cancelListener = mediator.callSync(
			'onStateRouter',
			'stateChangeEnd',
			() => this.set({ stateIsActive })
		)
	},
	ondestroy() {
		this.cancelListener()
	},
	data() {
		return {
			state: null,
			params: {},
			stateIsActive,
			inherit: false,
			className: '',
			anchor: '',
		}
	},
	computed: {
		hashFragment: ({ anchor }) => anchor ? `#${anchor}` : '',
		path: ({ state, params, options }) => makePath(state, params, options),
		href: ({ path, hashFragment }) => path + hashFragment
	},
	methods: {
		navigate(event) {
			if (shouldInterceptClick(event)) {
				event.preventDefault()

				const { state, params, inherit, hashFragment, anchor, path } = this.get()

				if (path !== currentPath()) {
					mediator.callSync('goWithHashFragment', state, params, { inherit }, anchor)
				} else {
					window.location = hashFragment
				}
			}
		}
	}
}
</script>
