import { adjacent } from 'chromatism2'

const colors = adjacent(-50, 8, '#cfddff').hex

const sections = {
	'genesis': 0,
	'exodus': 0,
	'leviticus': 0,
	'numbers': 0,
	'deuteronomy': 0,
	'joshua': 1,
	'judges': 1,
	'ruth': 1,
	'1samuel': 1,
	'2samuel': 1,
	'1kings': 1,
	'2kings': 1,
	'1chronicles': 1,
	'2chronicles': 1,
	'ezra': 1,
	'nehemiah': 1,
	'esther': 1,
	'job': 2,
	'psalms': 2,
	'proverbs': 2,
	'ecclesiastes': 2,
	'songofsolomon': 2,
	'isaiah': 3,
	'jeremiah': 3,
	'lamentations': 3,
	'ezekiel': 3,
	'daniel': 3,
	'hosea': 4,
	'joel': 4,
	'amos': 4,
	'obadiah': 4,
	'jonah': 4,
	'micah': 4,
	'nahum': 4,
	'habakkuk': 4,
	'zephaniah': 4,
	'haggai': 4,
	'zechariah': 4,
	'malachi': 4,
	'matthew': 5,
	'mark': 5,
	'luke': 5,
	'john': 5,
	'acts': 6,
	'romans': 6,
	'1corinthians': 6,
	'2corinthians': 6,
	'galatians': 6,
	'ephesians': 6,
	'philippians': 6,
	'colossians': 6,
	'1thessalonians': 6,
	'2thessalonians': 6,
	'1timothy': 6,
	'2timothy': 6,
	'titus': 6,
	'philemon': 6,
	'hebrews': 6,
	'james': 7,
	'1peter': 7,
	'2peter': 7,
	'1john': 7,
	'2john': 7,
	'3john': 7,
	'jude': 7,
	'revelation': 7,
}

export default Object.keys(sections).reduce((map, key) => {
	map[key] = colors[sections[key]]
	return map
}, Object.create(null))
