import client$47$service$47$after$45$state$45$transition$45$to$46$js from '../client/service/after-state-transition-to.js'
import client$47$service$47$get$45$book$45$by$45$id$46$js from '../client/service/get-book-by-id.js'
import client$47$service$47$go$45$with$45$hash$45$fragment$46$js from '../client/service/go-with-hash-fragment.js'
import client$47$service$47$navigation$45$keypress$45$handler$46$js from '../client/service/navigation-keypress-handler.js'
import client$47$service$47$reference$45$input$46$js from '../client/service/reference-input.js'
import client$47$service$47$scroll$45$to$45$top$46$js from '../client/service/scroll-to-top.js'
import client$47$service$47$set$45$anchor$45$after$45$state$45$transition$46$js from '../client/service/set-anchor-after-state-transition.js'

export default [
	client$47$service$47$after$45$state$45$transition$45$to$46$js,
	client$47$service$47$get$45$book$45$by$45$id$46$js,
	client$47$service$47$go$45$with$45$hash$45$fragment$46$js,
	client$47$service$47$navigation$45$keypress$45$handler$46$js,
	client$47$service$47$reference$45$input$46$js,
	client$47$service$47$scroll$45$to$45$top$46$js,
	client$47$service$47$set$45$anchor$45$after$45$state$45$transition$46$js
]
