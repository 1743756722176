<div class="book-names">
	{#each bookNames as name}
		<div class="book" style="background-color: { getBookColor(name.id) }">
			<StateLink state="main.text" params="{ { book: name.id } }">
				<div class="short-name">
					{name.short}
				</div>
				<div class="full-name">
					{name.full}
				</div>
			</StateLink>
		</div>
	{/each}
</div>

<style>
@import 'variables';

.book-names {
	padding: 4px;
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;

	font-family: sans-serif;
}
.book {
	height: 15vw;
	flex-basis: 25%;
	white-space: nowrap;

	outline: 1px solid grey;

	:global(a) {
		width: 100%;
		height: 100%;

		color: $black;

		text-decoration-line: none;

		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.short-name {
	display: none;
}

@media (max-width: 550px) {
	.short-name {
		display: inherit;
	}

	.full-name {
		display: none;
	}
}
@media (min-width: 1000px) {
	.book {
		flex-basis: 20%;
		height: 20vh;
	}
}
@media (min-width: 1300px) {
	.book {
		flex-basis: 16.6%;
	}
}
</style>

<script>
import books from 'books-of-the-bible'

import bookColors from 'lib/book-of-the-bible-colors.js'
import { getBookId } from 'lib/get-id.js'

import StateLink from 'component/StateLink.html'

export default {
	data() {
		return {
			books
		}
	},
	computed: {
		bookNames: ({ books }) => books.map(({ name, aliases }) => {
			const useFirstAliasForShort = name.length > 5 && aliases[0]

			return {
				full: name,
				short: useFirstAliasForShort ? aliases[0] : name,
				id: getBookId(name)
			}
		}),
	},
	helpers: {
		getBookColor: id => bookColors[id]
	},
	components: {
		StateLink
	}
}
</script>
