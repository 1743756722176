import client$47$view$47$main$46$js from '../client/view/main.js'
import client$47$view$47$book$45$selection$47$book$45$selection$46$js from '../client/view/book-selection/book-selection.js'
import client$47$view$47$home$47$home$46$js from '../client/view/home/home.js'
import client$47$view$47$not$45$found$47$not$45$found$46$js from '../client/view/not-found/not-found.js'
import client$47$view$47$reference$47$reference$46$js from '../client/view/reference/reference.js'
import client$47$view$47$text$47$text$46$js from '../client/view/text/text.js'

export default [
	client$47$view$47$main$46$js,
	client$47$view$47$book$45$selection$47$book$45$selection$46$js,
	client$47$view$47$home$47$home$46$js,
	client$47$view$47$not$45$found$47$not$45$found$46$js,
	client$47$view$47$reference$47$reference$46$js,
	client$47$view$47$text$47$text$46$js
]
