{#each children as chunk}
	{#if chunk.type === 'chapter number'}
		<LeftMarginNumber
			number={chunk.value}
			emphasized={true}
			id={ getChapterNumberId(chunk.value) }
		/>
	{:elseif chunk.type === 'verse number'}
		<LeftMarginNumber
			number={chunk.value}
			id={ getChapterVerseId(chunk.chapterNumber, chunk.value) }
		/>
	{:elseif chunk.type === 'line break'}
		<br>
	{:else}
		<span
			class="verse-text"
			data-chapter-number={chunk.chapterNumber}
			data-verse-number={chunk.verseNumber}
			data-highlighted={isHighlighted(chunk.chapterNumber, chunk.verseNumber)}
		>
			{chunk.value}
		</span>
	{/if}
{/each}

<style>
@import 'variables';

[data-highlighted=true] {
	background-color: $backgroundYellow;
}
</style>

<script>
import { getChapterNumberId, getChapterVerseId } from 'lib/get-id.js'
import withinRange from 'multi-part-range-compare'

import LeftMarginNumber from './LeftMarginNumber.html'

export default {
	helpers: {
		getChapterNumberId,
		getChapterVerseId
	},
	components: {
		LeftMarginNumber,
	},
	computed: {
		comparableRange: ({ highlightedRange }) => highlightedRange
			? {
				start: [highlightedRange.startChapter, highlightedRange.startVerse],
				end: [highlightedRange.endChapter, highlightedRange.endVerse]
			}
			: null,
		isHighlighted: ({ comparableRange }) => comparableRange
			? (chapter, verse) => withinRange(comparableRange.start, comparableRange.end, [chapter, verse])
			: () => false
	}
}
</script>
