<div ref:container class="wat">
	<ReferenceSearchInput />
</div>

<style>
.wat {
	visibility: hidden;
}
</style>

<script>
import ReferenceSearchInput from './ReferenceSearchInput.html'

export default {
	components: {
		ReferenceSearchInput
	},
	oncreate() {
		const { top, left, right, bottom } =  this.refs.container.getBoundingClientRect()

		this.get().mediator.callSync('position search box', {
			top,
			left,
			right,
			bottom,
		})
	},
	ondestroy() {
		this.get().mediator.callSync('unposition search box')
	}
}
</script>
